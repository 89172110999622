<template>
    <section>
        <div class="success-icon"></div>
        <div class="title-lg">已提交資料</div>
        <div class="title-sm">工作人員會在5個工作天内聯絡你</div>
        <primary-button
            radius="none"
            class="next-button"
            @click="button"
        >
            回到主頁
        </primary-button>
    </section>
</template>

<script>
export default {
    methods:{
        button(){
            this.$store.commit("updateStore");
            this.$router.push({name:'index'});
        }
    }
};
</script>

<style lang="scss" scoped>
@import "./newDonation.scss";
section {
    @include fc-c-c;
    position: relative;
    padding-bottom: 56px;
    .success-icon {
        background-image: url("../../assets/img/finished.png");
        background-position: center;
        background-size: cover;
        width: 80px;
        height: 80px;
    }
    .next-button {
        position: absolute;
        right: 16px;
        bottom: 16px;
    }
}
</style>